@import url('https://fonts.googleapis.com/css2?family=Cabin:wght@500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');

:root {
  --primaryColor: #faf9f8;
  --primaryWhite: #ffffff;
  --secColor: #000B26;
  --terColor: #faf9f8;
  --appBg: #faf9f8;
}

body {
  font-family: 'Open Sans', sans-serif !important;
  color: var(--secColor) !important;
}

.fullPage {
  min-height: 80vh;
}

.App {
  background-color: var(--appBg);
  font-family: 'Open Sans', sans-serif !important;
  color: var(--secColor) !important;
  min-height: 100vh;
}

a {
  color: var(--appBg) !important;
}